<template>
    <div class="mx-3">
    <v-col></v-col>
      <v-col>
        <h2> {{ $t('Bills') }}</h2>
      </v-col>
      <v-col></v-col>
      <v-col  md="2">
          <v-btn dark
            :color="($route.query.invoice_status==2) ? 'green' : 'gray'"
            @click="addNewInvoice"
            > <v-icon >mdi-plus</v-icon>
          {{ $t('Add New Invoice') }}</v-btn
          >
        </v-col>
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1 py-5"
        hide-default-footer
        :items-per-page="10"
      >
        <template v-slot:item.actions="{ item }">
         
          <v-btn @click="invoiceTypeToHandler(item)" color="blue" icon>
            <v-icon class="ml-1">mdi-pen</v-icon></v-btn
          >
          <deleteItem  @record-deleted="getAll" path="inventories/invoices" :id="item.id"  />
        </template>
      </v-data-table>
      <v-pagination
        v-if="!loading && items.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { saveAs } from 'file-saver';
  import AutoCompleteField from "../invoices/AutoCompleteField.vue";
  import i18n from "../../../../i18n";
  import deleteItem from "../../components/core/delete.vue";
  export default {
    components: {   AutoCompleteField, deleteItem  },
    data: () => ({
     
      types: [
        {
          label: i18n.t('purchase'),
          value: "PURCHASES",
        },
        {
          label: i18n.t('sales'),
          value: "SALES",
        },
        {
          label: i18n.t('purchase refunds'),
          value: "PURCHASE_REFUNDS",
        },
        {
          label: i18n.t('sales refunds'),
          value: "SALES_REFUNDS",
        },
        {
          label: i18n.t('transportation'),
          value: "TRANSPORTATION",
        },
      ],
      tableItems: [],
      invoiceTypes: [],
      loading: false,
      page: 1,
      total: 1,
      headers: [
      { text: i18n.t('id'), value: "id" },
      { text: i18n.t('Invoice Number'), value: "serial" },
      { text: i18n.t('Order Number'), value: "order_serial" },
      { text: i18n.t('Invoice Date'), value: "date" },
      { text: i18n.t('Invoice Name'), value: "invoiceTypeName" },
      { text: i18n.t('Invoice Type'), value: "type" },
      { text: i18n.t('Client Name'), value: "clientAccountName" },
      { text: i18n.t('Paid Value'), value: "paid_amount" },
      { text: i18n.t('Total Value'), value: "total_amount" },
      { text: i18n.t('notes'), value: "notes" },
      { text: i18n.t('Invoice Stat'), value: "invoiceStatus" },
      { text: "", value: "actions" },
      ],
      items: [],
      filters: {
        serial: null,
        from: null,
        to: null,
        user_id: null,
        inventory_id: null,
        notes: null,
      },
    }),
    methods: {
        addNewInvoice(){
            if(this.$route.query.invoice_status==2)
            this.$router.push(`/createInvoiceFromOrder/${this.$route.params.id}`)
        },
      clearFilter() {
        this.filters.serial = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.user_id = undefined;
        this.filters.inventory_id = undefined;
        this.filters.notes = undefined;

        this.page = 1;
        this.getAll();
      },
      async exportToExcel(queryParams){
           
           try {
      
       const excel = await axios.get(`/inventories/invoices/export`, {
         params: {
           ...queryParams,
           
         },
         responseType: 'blob'
       });
       saveAs(excel.data)
       
  
     } catch (err) {
       console.log('err', err);
     } finally {
       
     }
       
       },
      invoiceTypeToHandler(item) {
       
        this.$router.push(
            `/edit-invoice/${item.id}?invoice_id=${item.invoice_type_id}`
          );

      },
      async getAll(page) {
        try {
          if (!page) this.page = 1;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          const res = await axios.get("/inventories/invoices/index", {
            params: {
              invoice_order_id: this.$route.params.id,
            },
          });
          this.tableItems = res.data.data.map((el) => {
            const typeTemp = this.types.find(
              (typ) => el?.invoice_type?.type == typ.value
            );
            return {
              ...el,
              invoiceTypeName: el?.invoice_type?.name,
              clientAccountName: el?.client_account?.name,
              type: typeTemp ? typeTemp.label : " ",
              invoiceStatus: el.is_locked ? "Locked" : "UnLocked",
            };
          });
  
          this.total = res.data.meta.total;
        } finally {
          this.loading = false;
        }
      },
 
      itemClicked(v) {
     
          this.$router.push(`/new-invoice?invoice_id=${v.id}`);
        
      },
  
    },
    created() {
      
    this.getAll();
    },
  };
  </script>
  
  <style></style>
  